@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("//cdn.web-fonts.ge/fonts/bpg-algeti-compact/css/bpg-algeti-compact.min.css");

body {
    background: linear-gradient(to top, lightgrey 0%, lightgrey 1%, #e0e0e0 26%, #efefef 48%, #d9d9d9 75%, #bcbcbc 100%);
    background-repeat: no-repeat;
    background-size: cover;
    font-family: "BPG Algeti Compact", sans-serif;
   overflow-x: hidden;
   min-height: 100vh;
}

.sidebar {
    background: linear-gradient(to top, #e6e9f0 0%, #eef1f5 100%);
}


.line {
    position: relative;
}

.line::after {
    position: absolute;
    content: "";
    background-color: black;
    width: 100%;
    height: 2px;
    top: -10px;
    left: 0;

}

.whiteline {
    position: relative;
}

.whiteline::after {
    position: absolute;
    content: "";
    background-color: white;
    width: 100%;
    height: 2px;
    top: -10px;
    left: 0;

}
a {
    position: relative;
}

a::after {
    position: absolute;
    content: "";
    width: 0%;
    height: 2px;
    background-color: black;
    left: 0;
    bottom: 0;
    transition: all 0.8s cubic-bezier(0.25, 0.46, 0.45, 0.94);

}

a:hover::after {
    width: 100%;
}

.fileInput {
visibility: hidden;

}

.input {
  width: 500px;

}

.icon {
    visibility: hidden;
}

@media only screen and (max-width: 600px) {
    .input {
       visibility: hidden;
    }  

    .icon {
        visibility: visible;
    }
  }